<template>
  <v-container>
    <v-row><v-col><span class="body-l-semibold">Тек. время: {{ curDateTime }}</span></v-col> </v-row>
    <v-row><v-col><span class="body-l-semibold">Версия: {{ $config.app.version }}</span></v-col> </v-row>
    <v-row><v-col><span class="body-l-semibold">API: {{ $config.app.apiHost }}</span></v-col> </v-row>

    <v-row>
      <v-col>
        <v-skeleton-loader
          boilerplate
          type="card-avatar, article, actions"
        />
      </v-col>
    </v-row>
  </v-container>
</template>
<script>
  export default {

    data () {
      return {
        curDateTime: null,
        updateDateTask: null,
      }
    },
    mounted () {
      this.runTask()
    },
    destroyed () {
      this.stopTask()
    },
    methods: {
      stopTask () {
        if (this.updateDateTask) clearInterval(this.updateDateTask)
      },
      runTask () {
        this.updateDateTask = setInterval(() => {
          this.updateDate()
        }, 1000)
      },
      updateDate () {
        this.curDateTime = this.$moment().format(this.$config.date.DATETIME_FORMAT)
      },
    },
  }
</script>
